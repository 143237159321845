import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import './Product.css';

const DemoPaper = styled(Paper)(({ theme }) => ({
	  display: 'flex',
	  flexWrap: 'wrap',
	  width: 300,
	  height: 350,
	  padding: theme.spacing(2),
	  ...theme.typography.body2,
	  textAlign: 'center'
}));

const Product = () =>{
	return(
	<>
	<br/>
	<h3>All Products</h3>
		<Stack direction="row" spacing={2} margin="30px">
		    <DemoPaper sx={{':hover': {boxShadow: 20},}}>
		    	<img className="photo" src="https://www.godrejinterio.com/imagestore/B2C/56101543SD00017/56101543SD00017_01_500x500.jpg" alt="table"/>
		    	<text> Brown Neemwood Table </text>
		    	<hr/> 
		    	<text>$35 </text>
		    </DemoPaper> 
		      <DemoPaper sx={{':hover': {boxShadow: 20},}}>
		      	<img className="photo" src="https://www.godrejinterio.com/imagestore/B2C/56101515SD00220/56101515SD00220_01_803x602.jpg" alt="chair"/>
		      	<text> Brown Neemwood Bed </text>
		    	<hr/> 
		    	<text>$400 </text>
		      </DemoPaper>
		      <DemoPaper sx={{':hover': {boxShadow: 20},}}>
		      	<img className="photo" src="https://www.godrejinterio.com/imagestore/B2C/56101508SD07918/56101508SD07918_10_803x602.jpg" alt="Mattress"/>
		      	<text> Foam Mattress </text>
		    	<hr/> 
		    	<text>$150 </text>
		      </DemoPaper>
		      <DemoPaper sx={{':hover': {boxShadow: 20},}}>
		      	<img className="photo" src="https://www.godrejinterio.com/imagestore/B2C/52121512SD01603/52121512SD01603_01_803x602.jpg" alt="cushion"/>
		      	<text> Abstract Cushions </text>
		    	<hr/> 
		    	<text>$15 </text>
		      </DemoPaper>
	     </Stack>
	     <Stack direction="row" spacing={2} margin="30px">
		       <DemoPaper sx={{':hover': {boxShadow: 20},}}>
		    	<img className="photo" src="https://www.godrejinterio.com/imagestore/B2C/46171591SD00709/46171591SD00709_01_803x602.jpg" alt="table"/>
		    	<text> White Security Safe </text>
		    	<hr/> 
		    	<text>$35 </text>
		    </DemoPaper> 
		      <DemoPaper sx={{':hover': {boxShadow: 20},}}>
		      	<img className="photo" src="https://www.godrejinterio.com/imagestore/B2C/56101537SD00081/56101537SD00081_01_500x500.jpg" alt="chair"/>
		      	<text> Mirrored Dressing Table </text>
		    	<hr/> 
		    	<text>$200 </text>
		      </DemoPaper>
		      <DemoPaper sx={{':hover': {boxShadow: 20},}}>
		      	<img className="photo" src="https://www.godrejinterio.com/imagestore/B2C/39111510SD00048/39111510SD00048_02_803x602.jpg" alt="Mattress"/>
		      	<text> Stick Lamp </text>
		    	<hr/> 
		    	<text>$170 </text>
		      </DemoPaper>
		      <DemoPaper sx={{':hover': {boxShadow: 20},}}>
		      	<img className="photo" src="https://www.godrejinterio.com/imagestore/B2C/56121403SD00241/56121403SD00241_01_803x602.jpg" alt="cushion"/>
		      	<text> Portable Bed Table</text>
		    	<hr/> 
		    	<text>$40 </text>
		      </DemoPaper>
	     </Stack>
	</>
	);
}

export default Product;