import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { createTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import './Header.css';

const mystyle = {
		textDecoration: "none",
		color: "#34240A",
}


const Header = () =>{
	return(
		<Box 
		height={30} 
		padding = {2}
		sx={{bgcolor:'#F0D0A7  ', 
		display: 'flex',
	    flexWrap: 'wrap'}}>
			<ButtonGroup variant="text" size='medium'>
				<Button> 
					<a style={mystyle} href="home"><b>Home</b></a> 
				</Button>
				<Button > 
					<a style={mystyle} href="Product"><b>Products</b></a>
				 </Button>
				<Button> 
					<a style={mystyle} href="Contact"><b>Contact Us</b></a> 
				</Button>
				<Button>
					<a style={mystyle} href="About"><b>About Us</b></a>
				</Button>
			</ButtonGroup>
		</Box>
	);
}
export default Header;