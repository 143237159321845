import './App.css';
import Student from './components/Student.js';
import Header from './components/Header.js';
import Home from './components/Home.js';
import Product from "./components/Products/Product.js";
import Contact from "./components/Contact.js";
import About from "./components/About.js";
import {Route,Routes, BrowserRouter} from 'react-router-dom';
import Footer from "./components/Footer.js";

const App = () => {
  return (
    <>
    <Header/>
    <BrowserRouter base="/">
      <Routes>
        <Route path="home" element={<Home/>} />
        <Route path="Product" element={<Product/>} />
        <Route path="Contact" element={<Contact/>}/>
        <Route path="About" element={<About/>}/>
      </Routes>
    </BrowserRouter>
    <Footer/>
    </>
    );
}

export default App;
