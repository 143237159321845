import React from 'react';
import {Box, Grid} from '@mui/material';
import './Footer.css';

const styles ={
	width: "100%" ,
	border: '0.5px solid silver',
	paddingLeft: '10px',
	paddingTop:'30px',
	paddingBottom: '30px'
}

const Footer = () =>{
	return(
	<div className="Footerrr">
		 <Box sx={{ flexGrow: 1 }}>
	      <Grid container alightItems="stretch" >
	        {/*Box 1:- Contact Us*/}
	        <Grid item xs={4} md={4} display="flex">
	          	<Box sx={styles}>
	         	 	<text>Contact Us </text><br/>
	         	 	<br/>
	         	 	<u> interiorsvindhyachal@gmail.com</u><br/><br/>
	         	 	<number> Ph: 93503 77382, <br/> &emsp; 7011644391 </number>
	         	</Box>
	        </Grid>

	        {/*Box 2:- About Us*/}
	        <Grid item xs={2} md={2} display="flex">
	        	<Box sx={styles}>
	          		<text>About Us </text><br/><br/>
	          		<text>About Vindhyachal </text><br/>
	          		<text> Store</text><br/>
	          		<text> Careers</text><br/>
	          		<text> Projects</text><br/>
	          		<text> Responsibility</text>
	          	</Box>
	        </Grid>

	        {/*Box 3:- Online Support*/}
	        <Grid item xs={2} md={2} display="flex">
		        <Box sx={styles}>
		          	<text>Online Support </text><br/><br/>
	          		<text>Contact </text><br/>
	          		<text> Size guide</text><br/>
	          		<text>Care guide</text><br/>
	          		<text> Shipping and returns</text><br/>
	          		<text> FAQs</text><br/>
		         </Box>
	        </Grid>

	        {/*Box 4:- Connect*/}
	        <Grid item xs={2} md={2} display="flex">
		        <Box sx={styles}>
		          	<text>Connect </text><br/><br/>
	          		<text>Instagram </text><br/>
	          		<text> Pinterest</text><br/>
	          		<text>Facebook</text><br/>
	          		<text>LinkdIn</text>
		          </Box>
	        </Grid>
	        {/*Box 4:- Legal Terms*/}
	        <Grid item xs={2} md={2} display="flex">
	        	<Box sx={styles}>
	          		<text>Legal Terms </text><br/><br/>
	          		<text>Privacy Policy </text><br/>
	          		<text> Terms and Conditions</text>
	          	</Box>
	        </Grid>
	      </Grid>
	    </Box>
	</div>
	);
}

export default Footer;