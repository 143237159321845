import React from 'react';
import './Home.css';
import water from './water.mp4';

const Home = () =>{
	return(
		<>
			<div id="img">
			  	<img src="https://www.bergerpaints.com/blog/wp-content/uploads/2023/10/shutterstock_2263948173-1.jpg" alt="image"/> 
			</div>
			<div id="text-img">
				<h1 className="quote">Everything is designed. Few things are designed well.</h1>
				<p> About Us</p>
				
			</div>
		</>
		);
}

export default Home;